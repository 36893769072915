/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '~highlight.js/styles/github.css';
// @import '~highlight.js/styles/vs2015.css';
// @import '~highlight.js/styles/atom-one-dark.css';
// @import '~highlight.js/styles/stackoverflow-dark.css';

.f-l {
  float: left;
}

.f-r {
  float:right;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.f-l {
  float: left;
}
